import React, { useEffect } from 'react';
import useSWR, { SWRConfig, mutate } from 'swr';
import Axios from 'axios';

import { API_URL, SecureHeader, getStock } from '../utils';
import { useAuthState, useCartDispatch, useAuthDispatch } from '../contexts';

const getToken = (): string =>
  (JSON.parse(localStorage.getItem('session') || '') || {}).token || '';
const fetcher = async (url: string) =>
  Axios.get(url, {
    headers: {
      authorization: `Bearer ${getToken()}`,
      'content-type': 'application/json',
    },
  }).then((res: any) => res.data);
const KEY = `${API_URL}/auth/profile`;

export default function RefreshSync(props: any) {
  const authState = useAuthState();
  const cartDispatch = useCartDispatch();

  useEffect(() => {
    /**
     * If user is logged in reload cart history from their profile
     */
    console.log('Refreshing user data.');
    if (!authState.user) return;
    fetcher(KEY)
      .then((profile: any) => {
        if (!profile) return;
        // profile?.incompleteCart.forEach((item: any) => {
        //   cartDispatch({ type: 'ADD TO CART', payload: item });
        // });
        profile?.wishlist.forEach(({ barcode }: any) =>
          cartDispatch({ type: 'ADD TO WISHLIST', payload: { barcode } })
        );
      })
      .catch(console.error);
  }, [authState]);

  return (
    <SWRConfig
      value={{
        refreshInterval: 30000,
        fetcher: (args) => fetch(args).then((res: any) => res.json()),
      }}
    >
      {props.children}
    </SWRConfig>
  );
}
