import React, { createContext } from "react";

import { cartReducer } from "./reducers";

const CartStateContext = createContext();
const CartDispatchContext = createContext();

const InitialCartState = {
  items: [],
  toEdit: [],
  wishlist: [],
  currentItem: null,
  shipping: false,
};

function CartProvider({ children }) {
  const [state, dispatch] = React.useReducer(cartReducer, InitialCartState);
  return (
    <CartStateContext.Provider value={state}>
      <CartDispatchContext.Provider value={dispatch}>
        {children}
      </CartDispatchContext.Provider>
    </CartStateContext.Provider>
  );
}

function useCartState() {
  const context = React.useContext(CartStateContext);
  if (context === undefined) {
    throw new Error("useCartState must be within CartProvider");
  }
  return context;
}
function useCartDispatch() {
  const context = React.useContext(CartDispatchContext);
  if (context === undefined) {
    throw new Error("useCartDispatch must be within a CartProvider");
  }
  return context;
}

export { CartProvider, useCartState, useCartDispatch };

////
