import React, { createContext } from "react";

import { userReducer } from "./reducers";

const UserStateContext = createContext();
const UserDispatchContext = createContext();

function UserProvider({ children }) {
  const [state, dispatch] = React.useReducer(userReducer, { users: [] });
  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>{children}</UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  const context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be within UserProvider");
  }
  return context;
}
function useUserDispatch() {
  const context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch };
