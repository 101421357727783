import React, { useState, FormEvent, useEffect } from 'react';
import {
  useCartState,
  useAuthState,
  useAuthDispatch,
  useCartDispatch,
} from '../contexts';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { API_URL, getLocalSession, getLocalCart } from '../utils';
import {
  Paper,
  IconButton,
  InputBase,
  createStyles,
  makeStyles,
  Theme,
  Badge,
} from '@material-ui/core';
import {
  SearchOutlined,
  ShoppingBasketOutlined,
  BookmarkBorder,
  HomeOutlined,
} from '@material-ui/icons';

const departments = [
  { name: 'all', icon: '' },
  { name: 'books', icon: '' },
  {
    name: 'computers',
    icon: 'https://image.flaticon.com/icons/svg/896/896530.svg',
  },
  { name: 'electronics', icon: '' },
  { name: 'office', icon: '' },
  { name: 'stationery', value: 'stationary', icon: '' },
];

export function SideNav() {
  return (
    <aside className="nav nav--side">
      <div className="nav-offset"></div>
      <div className="group">
        <summary className="group__head">Jump to...</summary>
        <div className="links">
          {departments.map((d: any) => (
            <Link key={d.name} to={'/list/' + d.name} className="link">
              <span
                className="icon"
                style={{
                  backgroundColor: `rgba(0,0,0,.1)`,
                }}
              ></span>
              <span className="text">{d.name}</span>
            </Link>
          ))}
        </div>
      </div>
      <div style={{ height: '3rem' }} className="group"></div>
      <div className="group group--strip">
        <a
          className="icon icon--badge"
          style={{
            backgroundImage: `url(https://image.flaticon.com/icons/svg/747/747374.svg)`,
          }}
          href="https://www.facebook.com/BznTechnologies"
          target="_blank"
        ></a>
        <a
          className="icon icon--badge"
          style={{
            backgroundImage: `url(https://image.flaticon.com/icons/png/512/1051/1051382.png)`,
          }}
          href="/"
          target="_blank"
        ></a>
        <a
          className="icon icon--badge"
          style={{
            backgroundImage: `url(https://image.flaticon.com/icons/svg/1384/1384089.svg)`,
          }}
          href="/"
          target="_blank"
        ></a>
      </div>
      <div className="group group--foot">
        <li className="link">© 2020 BZN Technologies</li>
      </div>
    </aside>
  );
}

export function BottomNav() {
  const { user } = useAuthState();
  const cartState = useCartState();
  const cartCount = cartState.items.reduce(
    (acc: number, curr: any) => acc + curr.count,
    0
  );

  return (
    <nav className="nav nav--bottom">
      <IconButton className="link">
        <HomeOutlined />
      </IconButton>
      <IconButton className="link">
        <SearchOutlined />
      </IconButton>
      <Link to="/checkout">
        <IconButton title="Click to view your cart">
          <Badge badgeContent={cartCount} color="primary">
            <ShoppingBasketOutlined />
          </Badge>
        </IconButton>
      </Link>
      <Link className="link" to="/profile">
        <div className="avatar">
          <div className="avatar__img">
            <img
              src={
                user
                  ? user.picture
                  : 'https://image.flaticon.com/icons/png/512/126/126486.png'
              }
              alt={user?.fullname}
            />
          </div>
        </div>
      </Link>
    </nav>
  );
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: '40vw',
      marginLeft: '5vw',
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
      background: '#fff',
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
  })
);

export function TopNav(props: { history: any[] }) {
  const { user } = useAuthState();
  const authDispatch = useAuthDispatch();
  const cartDispatch = useCartDispatch();
  const [searchQuery, setSearchQuery] = useState('');
  const cartState = useCartState();
  const cartCount = cartState.items.reduce(
    (acc: number, curr: any) => acc + curr.count,
    0
  );
  const wishlistCount = cartState.wishlist.length;
  const localStoreEffect = () => {
    // cartDispatch({ type: "SET CART", payload });
    const session = getLocalSession();
    const cart = getLocalCart();

    if (cart) {
      cartDispatch({ type: 'SET CART', payload: cart });
    }
    if (session) {
      authDispatch({ type: 'LOGIN', payload: session });
    }
  };
  useEffect(localStoreEffect, []);

  function handleLogout() {
    authDispatch({ type: 'LOGOUT' });
    localStorage.clear();
  }

  function handleChange(ev: any) {
    console.log(ev.target.value);
    setSearchQuery(ev.target.value);
  }

  function handleSubmit(ev: FormEvent) {
    ev.preventDefault();
    handleSearch(searchQuery);
    console.log(searchQuery);
  }

  function handleSearch(_query: string) {
    // const { token } = JSON.parse(localStorage.getItem("session") || "");
    if (!_query) {
      return props.history.push(`/list/all`);
    }
    LogSearchQuery(_query);
    props.history.push(`/results?q=product&query=${_query}`);
  }

  function LogSearchQuery(_query: string) {
    let payload: any = { searchString: _query };
    if (user) {
      payload = { ...payload, token: user.token };
    }
    Axios.post(API_URL + '/reports/logsearch', payload)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  }

  const classes = useStyles();

  return (
    <nav className="nav nav--top">
      <div className="logo-wrap">
        <Link to="/" className="icon logo"></Link>
      </div>
      <Paper component="form" className={classes.root} onSubmit={handleSubmit}>
        <InputBase
          className={classes.input}
          placeholder="Search here..."
          inputProps={{ 'aria-label': 'search google maps' }}
          onChange={handleChange}
        />
        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="search"
        >
          <SearchOutlined />
        </IconButton>
      </Paper>
      <div className="nav__links">
        <Link to="/checkout">
          <IconButton title="Click to view your cart">
            <Badge badgeContent={cartCount} color="primary">
              <ShoppingBasketOutlined />
            </Badge>
          </IconButton>
        </Link>
        <Link to="/profile#wishlist">
          <IconButton title="Click to view your wishlist">
            <Badge badgeContent={wishlistCount} color="primary">
              <BookmarkBorder />
            </Badge>
          </IconButton>
        </Link>
        {/* <select name="account" id="account">
          <a href="/" className="link">Register</a>
          <a href="/" className="link">Login</a>
        </select> */}
        <span className="drop-down">
          <label htmlFor="account">
            {!user ? (
              'Account'
            ) : (
              <Link className="link" to="/profile">
                <div className="avatar">
                  <div className="avatar__img">
                    <img src={user.picture} alt={user.fullname} />
                  </div>
                  <span className="avatar__text">
                    {user?.fullname.substring(0, user.fullname?.indexOf(' '))}
                  </span>
                </div>
              </Link>
            )}
          </label>
          {!user ? (
            <div className="links">
              <Link className="link" to="/register">
                Register
              </Link>
              <Link className="link" to="/login">
                Login
              </Link>
            </div>
          ) : (
            <div className="links">
              <label onClick={handleLogout} className="link">
                Logout
              </label>
            </div>
          )}
        </span>
      </div>
    </nav>
  );
}
