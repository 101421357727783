import React from 'react';
import { useCartDispatch, useCartState } from '../contexts';
import { Link } from 'react-router-dom';

//Material UI
import { IconButton, Badge } from '@material-ui/core';
import {
  AddShoppingCartOutlined,
  BookmarkBorder,
  ShoppingCartOutlined,
  Bookmark,
} from '@material-ui/icons';

export default function Product(props: any) {
  // const { data: p, cart } = props;
  const { data: p } = props;

  const cartDispatch = useCartDispatch();
  const cart = useCartState();

  function handleCartAdd(product: any) {
    const itemOnCart = cart.items.find((_p: any) => _p.barcode === p.barcode);
    // console.log({ count: itemOnCart.count })
    if (product.quantity - 1 < itemOnCart?.count) return;
    cartDispatch({ type: 'ADD TO CART', payload: p });
  }

  function handleWishlistAdd(product: any) {
    const itemOnCart = cart.items.find((_p: any) => _p.barcode === p.barcode);
    if (product.quantity - 1 < itemOnCart?.count) return;
    cartDispatch({ type: 'ADD TO WISHLIST', payload: product });
  }

  const itemOnCart = (current: any) =>
    cart.items.find((_p: any) => _p.barcode === current.barcode);

  // useEffect(() => {}, [cart]);

  return (
    <figure className="product">
      {p.featured && (
        <div className="badge badge--sale">
          {100 - Math.round((p.price / p.prevPrice) * 100)}% off
        </div>
      )}
      <img
        src={p.images.length ? p.images[0] : ''}
        alt="alt"
        className="img img--bg"
      />
      <figcaption>
        <Link
          className="product__details"
          to={{
            pathname: `/product/${p.barcode}`,
            state: { ...p, cart },
          }}
        >
          <h3 className="product__name">{p.name}</h3>
          <div className="prices">
            <span className="product__price">R{p.price}</span>
            {p.featured && (
              <span className="product__price product__price--markdown">
                R{p.prevPrice}
              </span>
            )}
          </div>
        </Link>
        {/* !!TODO: Make it round bro on hover, with padding. */}
        <div className="product__actions">
          <span></span>
          {p.quantity > 0 ? (
            <IconButton
              onClick={() => handleCartAdd(p)}
              key={p.barcode}
              className="btn btn--icon"
            >
              {!itemOnCart(p) ? (
                <AddShoppingCartOutlined />
              ) : (
                <Badge badgeContent={itemOnCart(p)?.count}>
                  <ShoppingCartOutlined />
                </Badge>
              )}
            </IconButton>
          ) : (
            <IconButton
              title="Out of stock, add to wishlist."
              onClick={() => handleWishlistAdd(p)}
              key={p.barcode}
              className="btn btn--icon"
            >
              {!cart.wishlist.find((_p: any) => _p.barcode === p.barcode) ? (
                <BookmarkBorder />
              ) : (
                <Bookmark />
              )}
            </IconButton>
          )}
        </div>
      </figcaption>
    </figure>
  );
}
