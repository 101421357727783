import React, { Suspense } from 'react';
import { Paper } from '@material-ui/core';
import Footer from './Footer';
import { TopNav, SideNav, BottomNav } from './Nav';

export function Page(props: any) {
  return (
    <div className="page">
      {!props.hideTopNav && <TopNav {...props} />}
      {props.showSideNav && <SideNav {...props} />}
      {!props.hideBottomNav && <BottomNav {...props} />}

      <div className="content">
        <Suspense fallback={<Loader />}>{props.children}</Suspense>
      </div>
      <Footer />
    </div>
  );
}
export const PageWrap = Page;

export function Loader() {
  return (
    <div className="loader">
      <p>Loading</p>
    </div>
  );
}
