import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
`;
export const StyledFieldset = styled.fieldset`
  --border: 0.5px solid rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  /* padding: 1rem 0; */
  height: ${({ height }: any) => height || "6rem"};
  border: var(--border);
  border-right: ${({ error }: any) =>
    error ? "0.5px solid var(--color-accent)" : "var(--border)"};
`;

export const StyledLabel = styled.label`
  position: absolute;
  top: 1rem;
  left: 1rem;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  user-select: none;
  z-index: 1;
`;
export const StyledInput = styled.input`
  height: 5em;
  width: 100%;
  display: flex;
  border: none;
  border-left: 2px solid;
  border-left-color: ${({ error }: any) =>
    error ? "var(--color-accent)" : "transparent"};
  padding: 0 1rem;
  background: transparent;
  font-size: 16px;
  color: #020a02;
  transform: scaleY(0.98);
  transition: all 800ms ease-out;
  &::placeholder {
    font-size: 16px;
    line-height: 6rem;
    vertical-align: center;
  }
  &:focus {
    outline: none;
    border-left-color: ${({ error }: any) =>
    error ? "var(--color-accent)" : "var(--color-primary)"};
    transform: scaleY(1);
  }
  &:error {
    background: red;
  }
`;
export const StyledButton = (props: any) => {
  const SButton = styled.button`
    --bg: ${() => (props.primary ? "var(--color-primary)" : "transparent")};
    --filt-change: ${() => (props.primary ? 1.2 : 0.8)};
    --color: ${() => (props.primary ? "var(--color-base)" : "#000")};

    height: 3rem;
    padding: 0 4rem;
    border: 2px solid var(--bg);
    outline: none;
    background: var(--bg);
    color: var(--color);
    font-size: 14px;
    cursor: pointer;
    &:hover {
      filter: brightness(var(--filt-change));
    }
  `;
  console.log();
  return <SButton {...props}>{props.children}</SButton>;
};
export const StyledButtonLink = styled(Link)`
  --bg: ${({ primary }: any) =>
    primary ? "var(--color-light)" : "transparent"};
  --filt-change: ${({ primary }: any) => (primary ? 1.2 : 0.8)};

  display: inline-block;
  line-height: 3rem;
  padding: 0 4rem;
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.1);
  outline: none;
  background: var(--bg);
  color: var(--color);
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.08);
    box-shadow: none;
  }
`;
export const Row = styled.div`
  border: none;
  outline: none;
  padding: 2rem 0;
  font-size: 14px;
  display: flex;
  gap: 0.5rem;
`;

export function StyledField({
  label,
  name,
  type,
  inputMode,
  placeholder,
  errors,
  register,
  regOptions,
  required,
  handleFocus,
  ...rest
}: any) {
  const pattern = type === "number" ? /[*1234567890]/ : /\w+/;
  return (
    <StyledFieldset>
      <StyledLabel htmlFor={name}>{label}</StyledLabel>
      <StyledInput
        name={name}
        id={name}
        type={type || "text"}
        inputMode={inputMode || "text"}
        placeholder={placeholder || ""}
        ref={register({ required: !!required, pattern, ...regOptions })}
        required={required}
        onFocus={handleFocus}
        error={errors[name]}
        {...rest}
      />
    </StyledFieldset>
  );
}
