import Axios from 'axios';
import { StockItem } from '../models/stock';
import { API_URL, getToken, SecureHeader } from '../utils';

let func: number;
export function persistCart(updatedCart: any) {
  //Should probably use SWR
  const items = JSON.stringify({ items: updatedCart.items });
  const wishlist = JSON.stringify({ wishlist: [...updatedCart.wishlist] });

  localStorage['bzn-cart'] = items;
  localStorage['bzn-wishlist'] = wishlist;

  // clearTimeout(func);
  // func = setTimeout(() => {
  //   localStorage.setItem("cart", updatedCart);

  //   Axios.post(`${API_URL}/auth/savecart`, updatedCart, SecureHeader()).catch(console.log);
  // }, 10 * 1000);
}

export function persistCartProfile(items: StockItem[]) {
  const token = getToken();

  localStorage['bzn-cart'] = JSON.stringify(items);

  // Axios.post(
  //   API_URL + "/auth/saveCart",
  //   { items },
  //   SecureHeader()
  // )
  //   .then(console.log)
  //   .catch(console.log);
}

export function persistWishlist(wishlist: StockItem[]) {
  const token = getToken();

  Axios.post(API_URL + '/auth/saveWishlist', { wishlist }, SecureHeader())
    .then(console.log)
    .catch(console.log);
}

export function editCartItem(item: StockItem, state: { items: any[] }) {
  const someEmpty = Object.values(item).some(
    (v) => v === '' || v === undefined
  );
  if (!item || someEmpty) return;

  return {
    ...state,
    items: state.items.map((p: { barcode: any }) =>
      p.barcode === item.barcode ? item : p
    ),
  };
}
