import { useState, useEffect } from "react";
import fuse from "fuse.js";
import qs from "qs";

import { API_URL } from "../utils";
import useSWR from "swr";
import { useLocation } from "react-router-dom";
import { isElectronic } from "./Index";
import { StockItem } from "../models/stock";

export function useFilteredProducts(criteria?: string) {
  const [filteredProducts, setFilteredProducts] = useState<StockItem[]>([]);

  const fetcher = (url: string) => fetch(url).then((r: any): StockItem[] => r.json());
  const { data, isValidating, error } = useSWR(API_URL + "/stock", fetcher, {
    refreshInterval: 0,
  });
  useEffect(() => {
    //TODO: format json response based on requesting client, remove sesnitive data.
    if (!data) return;
    switch (criteria) {
      case "all":
        setFilteredProducts(data);
        break;
      case "electronics":
        setFilteredProducts(isElectronic(data));
        break;
      case "featured":
        setFilteredProducts(data.filter((p: any) => p.featured));
        break;
      default:
        setFilteredProducts(
          data.filter((p: any) => new Set(p.department).has(criteria))
        );
        break;
    }
  }, [criteria, data, error, isValidating]);
  return [filteredProducts, error, isValidating];
}

export function useFuzzyResult(criteria: string) {
  const [filterItems, setFilteredItem] = useState<any[]>();
  const [query, setQuery] = useState(criteria || "");

  const fetcher = (url: string) => fetch(url).then((r: any) => r.json());
  const { data, isValidating, error, mutate } = useSWR(
    API_URL + "/stock",
    fetcher
  );

  useEffect(() => {
    //TODO: format json response based on requesting client, remove sesnitive data.
    if (!data) return;
    if (!query) setFilteredItem(data);
    const Fuse = new fuse(data, {
      keys: ["name", "barcode", "description"],
      threshold: 0.4,
    });
    const result = Fuse.search(criteria).slice().reverse();
    setFilteredItem(result);
  }, [query, data]);
  return [filterItems, isValidating, error, mutate, setQuery];
}

export function useQuery() {
  const _q = qs.parse(useLocation()?.search);
  return { q: "", query: "", ..._q };
}

export function useLocalStorage(key: string, initialValue: any) {
  // State to store our value

  // Pass initial state function to useState so logic is only executed once

  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key

      const item = window.localStorage.getItem(key);

      // Parse stored json or if none return initialValue

      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue

      console.log(error);

      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...

  // ... persists the new value to localStorage.

  const setValue = (value: any) => {
    try {
      // Allow value to be a function so we have same API as useState

      const valueToStore =
        value instanceof Function ? value(storedValue) : value;

      // Save state

      setStoredValue(valueToStore);

      // Save to local storage

      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case

      console.log(error);
    }
  };

  return [storedValue, setValue];
}

export function useSortedList(list: any[]) {
  const [sorted, setSorted] = useState();

  const [sort, setSort] = useState("price");
  const [order, setOrder] = useState("ascending");

  useEffect(() => {
    if (!list) return;

    let _arr = list.slice().sort((p: any, curr: any) => {
      const smallFirst = p[sort] < curr[sort] ? -1 : 1;
      const largeFirst = p[sort] > curr[sort] ? -1 : 1;
      return order.startsWith("ascend") ? smallFirst : largeFirst;
    });
    console.log({ _arr });
    setSorted(_arr);
  }, [list, sort, order]);

  return [sorted, sort, order, setOrder, setSort];
}
