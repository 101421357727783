import { editCartItem, persistCart, persistCartProfile, persistWishlist } from "../../services/cart.service";
import {
  addToCart,
  validatePayload,
} from "../../utils";

export function cartReducer(state, action) {
  const { type, payload } = action;
  switch (type) {

    case "ADD TO CART": {
      validatePayload({ expctedPayload: "object", payload });
      const updatedCart = addToCart(payload, state);
      persistCartProfile(updatedCart);
      return updatedCart;
    }

    case "REMOVE FROM CART": {
      validatePayload({ expctedPayload: "object", payload });
      const updatedCart = {
        ...state,
        items: state.items.filter((_p) => _p.barcode !== payload.barcode),
      };
      persistCart(updatedCart);
      return updatedCart;
    }

    case "CLEAR_CART": {
      const updatedCart = {
        ...state,
        items: [],
      };
      persistCart(updatedCart);
      return updatedCart;
    }

    case "ADD TO WISHLIST": {
      validatePayload({ expctedPayload: "object", payload });
      const wishlist = state.wishlist || [];
      if (!wishlist.find((p) => p.barcode === payload.barcode)) {
        wishlist.push(payload);
      }
      const updatedCart = { ...state, wishlist };
      persistCart(updatedCart);
      persistWishlist(wishlist);

      return updatedCart;
    }

    case "REMOVE FROM WISHLIST": {
      validatePayload({ expctedPayload: "object", payload });
      const updatedCart = {
        ...state,
        wishlist: state.wishlist.filter((p) => p.barcode !== payload.barcode),
      };
      persistCart(updatedCart);
      return updatedCart;
    }

    case "CLEAR WISHLIST": {
      const updatedCart = { ...state, wishlist: [] };
      persistCart(updatedCart);
      return updatedCart;
    }

    case "SET SHIPPING": {
      validatePayload({ expctedPayload: "boolean", payload });
      const updatedCart = { ...state, shipping: payload };
      persistCart(updatedCart);
      return updatedCart;
    }

    case "SET CART": {
      persistCart(payload);
      return payload;
    }

    case "EDIT CART ITEM": {
      validatePayload({ expctedPayload: "object", payload });
      const updatedCart = editCartItem(payload, state);
      persistCart(updatedCart);
      return updatedCart;
    }

    case "RESET CART": {
      const updatedCart = {
        items: [],
        toEdit: [],
        wishlist: [],
        currentItem: null,
      };
      return updatedCart;
    }
    
    default: {
      throw new Error(`Unhandle action type: ${type}`);
    }
  }
}
