import React from "react";
import styled from "styled-components";

export const StyledChip = ({ type, children }: any) => {
  const Chip = styled.label`
    --bg: ${() => {
      switch (type) {
        case "error": {
          return "rgba(255, 228, 228, 0.274)";
        }
        case "success": {
          return "rgba(168, 253, 168, 0.205)";
        }
        case "base": {
          return "rgba(0,0,0,0.1)";
        }
        default:
          throw new Error("You need atleast one descriptor");
      }
    }};
    --color: ${() => {
      switch (type) {
        case "error": {
          return "var(--color-red)";
        }
        case "success": {
          return "var(--color-primary)";
        }
        case "base": {
          return "var(--color-primary)";
        }
        default:
          throw new Error("You need atleast one descriptor");
      }
    }};

    background-color: var(--bg);
    color: var(--color);
    font-size: 14px;
    padding: 1rem;
    margin-bottom: 1rem;
    border-left: 4px solid currentColor;
    animation: grow 1000ms ease-out forwards;
  `;

  return <Chip>{children}</Chip>;
};
