import React, { useState, useEffect } from 'react';
import { useStockDispatch } from '../contexts';
import { SET_PRODUCTS } from '../contexts/reducers';
import { SideNav, TopNav, BottomNav } from '../components/Nav';
import Product from '../components/Product';
import { LinkMore } from '../components/LinkMore';
import { API_URL } from '../utils';
import Footer from '../components/Footer';
import { Snackbar } from '@material-ui/core';

import useSWR from 'swr';
import { StockItem } from '../models/stock';
import Fuse from 'fuse.js';

export function Index(props: any) {
  const featuredListStyle = { '--num-featured': '4' } as React.CSSProperties;

  const [products, setProducts] = useState<StockItem[]>([]);
  const [featured, setFeatured] = useState<StockItem[]>([]);
  const [stationery, setStationery] = useState<StockItem[]>([]);
  const [electronics, setElectronics] = useState<StockItem[]>([]);

  //Has cart items and .....stretch!! wishlist

  const productDispatch = useStockDispatch();

  const fetcher = (url: string) => fetch(url).then((r: Response) => r.json());
  const { data, error, isValidating } = useSWR(`${API_URL}/stock`, fetcher);

  useEffect(() => {
    const _data: StockItem[] = data || [];
    productDispatch({ type: SET_PRODUCTS, payload: _data });
    setProducts(_data);
    setFeatured(_data.filter((p: StockItem) => p.featured));
    setStationery(
      _data.filter((p: StockItem) => new Set(p.department).has('stationery'))
    );
    setElectronics(isElectronic(data));
  }, [data, error, isValidating, productDispatch]);

  return (
    <div className="root">
      <SideNav {...props} />
      <TopNav {...props} />
      <BottomNav {...props} />
      <Snackbar
        autoHideDuration={isValidating ? 400000 : 3000}
        open={isValidating}
      >
        <div>Refreshing...</div>
      </Snackbar>
      <main className="main">
        <header className="container container--banner">
          <h1 className="hero-head">
            Autumn savings on office <br />
            and school gear
          </h1>
          <p>
            Get whatever you need from school & office stationery <br /> All the
            way to your favourite electronics.
          </p>
          <a href="#featured" className="btn btn--primary">
            Shop Now
          </a>
        </header>
        <section id="featured" className="container">
          <div className="section__head">
            <h2 className="section__title">Featured</h2>
            <LinkMore to="/list/featured" state={featured} />
          </div>
          <div className="list list--featured" style={featuredListStyle}>
            {featured.slice(0, 2).map((p: StockItem) => (
              <Product key={p.barcode} data={p} />
            ))}
          </div>
        </section>
        <section className="container">
          <div className="section__head">
            <h2 className="section__title">Stationery</h2>
            <LinkMore to="/list/stationery" />
          </div>
          <div className="list list--grid">
            {stationery.slice(0, 4).map((p: StockItem) => (
              <Product key={p.barcode} data={p} />
            ))}
          </div>
        </section>
        <section className="container">
          <div className="section__head">
            <h2 className="section__title">Electronics</h2>
            <LinkMore to="/list/electronics" />
          </div>
          <div className="list list--grid">
            {electronics.slice(0, 4).map((p: StockItem) => (
              <Product key={p.barcode} data={p} />
            ))}
          </div>
        </section>
        <Footer />
      </main>
    </div>
  );
}
export const eletronicCompoundStr =
  'scanner | wifi | wireless | electronic| print | smart | phone | keyboard | laptop !bic click';

export function isElectronic(products: StockItem[]) {
  const fuse = new Fuse(products || [], {
    keys: ['name', 'description'],
  });
  const result = fuse.search(eletronicCompoundStr);
  return result;
}
