export function authReducer(state, action) {
  switch (action.type) {
    case "REGISTER": {
      return { user: action.payload, new: true, loading: false, error: false };
    }
    case "LOGIN": {
      return { user: action.payload, loading: false, error: false };
    }
    case "LOGOUT": {
      return { user: null, loading: false, error: false };
    }
    case "LOADING": {
      return { ...state, loading: true, error: false };
    }
    case "ERROR": {
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    }
    case "SUCCESS": {
      return { ...state, loading: false, error: false, success: true };
    }
    case "RESET": {
      return { ...state, loading: false, error: false, success: false };
    }
    case "UPDATE_PROFILE": {
      return { ...state, user: { ...state.user, ...action.payload } };
    }
    case "UPDATE_USER": {
      return { ...state, user: { ...action.payload } };
    }
    case "UPDATE_ADDRESS": {
      return {
        ...state,
        user: { ...state.user, address: { ...action.payload } },
      };
    }
    default: {
      throw new Error(`Unhandle action type: ${action.type}`);
    }
  }
}
