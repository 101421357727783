import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Snackbar,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ClearAll, Clear, ChevronLeft } from '@material-ui/icons';
import Axios, { AxiosResponse } from 'axios';
// OWN COMPONENTS
import { Page } from '../components/Containers';
//
import {
  useCartState,
  useAuthState,
  useAuthDispatch,
  useCartDispatch,
  useStockDispatch,
} from '../contexts';

import { API_URL, SecureHeader } from '../utils';
const getToken = (): string =>
  (JSON.parse(localStorage.getItem('session') || '') || {}).token || '';
const fetcher = (url: string) =>
  Axios.get(url, {
    headers: {
      authorization: `Bearer ${getToken()}`,
      'content-type': 'application/json',
    },
  }).then((res: any) => res.data);

export default function CheckOut(props: any) {
  const {
    data: profile,
    error,
    isValidating,
  } = useSWR(`${API_URL}/auth/profile`, fetcher, {
    refreshInterval: 120000,
  });

  const [count, setCount] = useState(1);

  const cartState = useCartState();
  const cartDispatch = useCartDispatch();

  const [cartSummary, setCartSummary] = useState({
    items: [],
    additionalCost: 0,
    itemsTotal: 0,
  });

  useEffect(() => {
    const _items =
      cartState.items.map((item: any) => ({
        barcode: item.barcode,
        name: item.name,
        price: item.price,
        count: item.count,
        subTotal: item.price * item.count,
      })) || [];
    const base = {
      items: _items,
      additionalCost: 0,
      itemsTotal: _items.reduce((acc: number, x: any) => acc + x.subTotal, 0),
    };
    if (cartState.shipping) {
      setCartSummary({
        ...base,
        additionalCost: 150,
      });
    } else {
      setCartSummary({ ...base, additionalCost: 0 });
    }
  }, [cartState.items, cartSummary.additionalCost, profile]);

  //Handle payment
  const [paidFor, setPaidFor] = useState(false);

  let checkoutRef = useRef<HTMLButtonElement>(null);

  const { user } = useAuthState();
  const authDispatch = useAuthDispatch();
  const stockDispatch = useStockDispatch();
  const [updateAddress, setUpdateAddress] = useState(false);
  const [isUserInfoConfirm, setIsUserInfoConfirmed] = useState(false);

  const checkoutFormRef = useRef<HTMLFormElement>(null);

  console.log({ checkoutFormRef });
  const live = process.env.NODE_ENV === 'production';
  const checkout = {
    url: live ? 'payfast.co.za' : 'sandbox.payfast.co.za',
    merchentId: live ? '10961223' : '10017110',
  };

  function prepareDetails() {
    //Check is use has user has shipping

    if (
      !user &&
      window.confirm("You're not authenticated Login or Register.")
    ) {
      props.history.push('/login', { from: props.location.pathname });
    } else if (user && Object.values(user?.address).length) {
      //Show update shopping details modal
      setUpdateAddress(true);
    } else {
      setUpdateAddress(true);
    }
  }
  function handleUpdateAddress() {
    Axios.post(`${API_URL}/auth/profile`, user, { ...SecureHeader() })
      .then((res: any) => {
        console.log(res.data);
      })
      .catch(console.error);
    setUpdateAddress(false);
    setIsUserInfoConfirmed(true);
  }

  function handleAddressFieldChange(event: any) {
    const payload = {
      ...user.address,
      [event.target.name]: event.target.value,
    };
    authDispatch({ type: 'UPDATE_ADDRESS', payload });
  }

  //Location
  interface IAlert {
    type: 'error' | 'success' | 'info' | 'warning' | undefined;
    msg: String;
  }
  const INIT_ALERT: IAlert = {
    type: undefined,
    msg: '',
  };
  const [alert, setAlert] = useState(INIT_ALERT);

  function success(pos: any) {
    //Bind location to state
    const payload = {
      ...user.address,
      geolocation: `${pos.coords.latitude}, ${pos.coords.longitude}`,
    };
    authDispatch({ type: 'UPDATE_ADDRESS', payload });
    setAlert({ type: 'success', msg: 'Location captured.' });
  }
  function errorC() {
    setAlert({ type: 'error', msg: 'Unable to retrieve location.' });
  }
  //Endlocation

  //Checkput v2
  const [orderNumber, setOrderNumber] = useState('');
  const [paying, setPaying] = useState(false);
  const chkFormRef = useRef<HTMLFormElement>(null);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    event.persist();

    if (!isUserInfoConfirm) {
      return prepareDetails();
    }
    event.target['amount'].value = calcTotal(
      cartSummary.itemsTotal + cartSummary.additionalCost
    );

    const items = cartState.items.map(({ barcode, count }: any) => ({
      barcode,
      count,
    }));
    // `https://my-json-server.typicode.com/typicode/demo/posts`,
    await Axios.post(
      `${API_URL}/orders/place`,
      { items },
      { ...SecureHeader() }
    )
      .then((res: AxiosResponse) =>
        setOrderNumber(res?.data?.report?.orderNumber || '')
      )
      .then(() => {
        //for payfash
        // event.target.submit()
        const reference = 'BZNTECH-' + orderNumber;
        const paymentPayload = {
          BankReference: '' + reference,
          TransactionReference: '' + reference,
          Amount: '' + cartSummary.itemsTotal.toFixed(2),
          Customer: profile.fullname || 'Unkown',
        };

        Axios.post(
          'https://us-central1-bzntech-server.cloudfunctions.net/ozowPayment',
          paymentPayload
        ).then((res: any) => {
          window.location.href = res.data as string;
        });
      });
  };
  return (
    <Page {...props}>
      {/* REGION ASYNC AND PERMISSIONS ALERT */}
      <Snackbar
        open={!!alert.type}
        autoHideDuration={6000}
        onClose={() => setAlert(INIT_ALERT)}
      >
        <Alert onClose={() => setAlert(INIT_ALERT)} severity={alert.type}>
          {alert.msg}
        </Alert>
      </Snackbar>
      {/* ENDREGION ASYNC AND PERMISSIONS ALERT */}

      {/* MAIN */}
      <div className="content--col content--checkout">
        <div>
          <div className="cart-wrapper">
            <Button
              startIcon={<ChevronLeft />}
              onClick={() => props.history.goBack()}
            >
              Back
            </Button>
            <div className="section__head">
              <h4 className="section__title">Cart</h4>
              <IconButton
                disabled={!cartState.items.length}
                title="Clear all cart items"
                onClick={() => {
                  if (window.confirm('Empty cart?')) {
                    cartDispatch({ type: 'CLEAR_CART' });
                  }
                }}
              >
                <ClearAll />
              </IconButton>
            </div>
            <div className="cart">
              {cartState.items.length ? (
                <>
                  <figure className="cart__item cart__item--head">
                    <span>Product</span>
                    <span className="right">Price</span>
                    <span>Quantity</span>
                    <span className="right">Total</span>
                    <span></span>
                  </figure>
                  {cartState.items.map((item: any) => (
                    <figure key={item.barcode} className="cart__item">
                      <Link
                        to={{
                          pathname: `/product/${item.barcode}`,
                          state: { ...item },
                        }}
                        className="cart__item-label"
                      >
                        <div className="img-wrap">
                          <img
                            className="img img--bg"
                            src={
                              item.images?.length
                                ? item.images[0]
                                : 'https://image.flaticon.com/icons/svg/2880/2880809.svg'
                            }
                            alt="product"
                          />
                        </div>
                        <span>
                          <div className="cart__item-name">{item.name}</div>
                          <div className="barcode">{item.barcode}</div>
                        </span>
                      </Link>
                      <span className="right">R{item.price}</span>
                      {/* <Counter count={item.count} handleCountChange={setCount} /> */}
                      <div {...props} className="counter">
                        {props.label && <label htmlFor="">{props.label}</label>}
                        {!!cartState.items.find(
                          (_p: any) => _p.barcode === item.barcode
                        ) && (
                          <div className="inputs">
                            <span
                              onClick={(event: any) => {
                                if (item.count < 2) return setCount(1);
                                cartDispatch({
                                  type: 'EDIT CART ITEM',
                                  payload: {
                                    ...item,
                                    count: item.count - 1,
                                  },
                                });
                              }}
                            >
                              -
                            </span>
                            <input
                              id={'counter'}
                              type="number"
                              min={1}
                              max={item.quantity}
                              step={1}
                              onMouseOver={(event: any) =>
                                event.target.select()
                              }
                              onFocus={(event: any) => event.target.select()}
                              onChange={(event: any) => {
                                const { value } = event.target;

                                if (
                                  value > item.quantity ||
                                  !value ||
                                  value < 1
                                )
                                  return;
                                setCount(value);
                                cartDispatch({
                                  type: 'ADD TO CART',
                                  payload: {
                                    ...item,
                                    count: Math.abs(+value),
                                  },
                                });
                              }}
                              value={item.count}
                              defaultValue={item.count}
                              // ref={countInputRef}
                            />
                            <span
                              onClick={() => {
                                if (item.count >= item.quantity) {
                                  return setCount(item.quantity);
                                } else {
                                  cartDispatch({
                                    type: 'EDIT CART ITEM',
                                    payload: {
                                      ...item,
                                      count: item.count + 1,
                                    },
                                  });
                                }
                              }}
                            >
                              +
                            </span>
                          </div>
                        )}
                      </div>
                      <span className="right">R{item.price * item.count}</span>
                      <IconButton
                        title="Remove from cart"
                        onClick={() => {
                          cartDispatch({
                            type: 'REMOVE FROM CART',
                            payload: item,
                          });
                        }}
                      >
                        <Clear />
                      </IconButton>
                    </figure>
                  ))}
                </>
              ) : (
                <>
                  <div className="empty banner">
                    <h3>Wow, Such empty!</h3>
                    <p>
                      It appears you havent picked up anything. <br /> Go ahead{' '}
                      <Link to="/">
                        <strong>click me</strong>
                      </Link>{' '}
                      to get back to the isles
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div>
          <div className="order__summary">
            <div className="card card--light">
              <h3>Order Summary</h3>
              <figure className="summary__item">
                <span className="feint summary__label">Item(s) Total</span>
                <span className="summary__value">
                  R{cartSummary.itemsTotal}
                </span>
              </figure>
              <figure className="summary__item">
                <span className="feint summary__label">Extras</span>
                <span className="summary__value">
                  R{cartSummary.additionalCost}
                </span>
              </figure>
            </div>
            <div className="card card--light">
              <figure className="summary__item">
                <h3 className="summary__label">Total</h3>
                <h3>R{cartSummary.itemsTotal + cartSummary.additionalCost}</h3>
              </figure>
            </div>

            <div className="card card--light">
              <figure className="summary__item">
                <label htmlFor="shipping">
                  Will you require <strong>shipping</strong>?
                </label>
                <div className="summary__label">
                  <input
                    type="checkbox"
                    name="shipping"
                    id="shipping"
                    defaultChecked={cartState.shipping}
                    onChange={(event: any) => {
                      setIsUserInfoConfirmed(!event.target.checked);
                      cartDispatch({
                        type: 'SET SHIPPING',
                        payload: event.target.checked,
                      });

                      if (event.target.checked) {
                        setCartSummary({ ...cartSummary, additionalCost: 150 });
                      } else {
                        setCartSummary({ ...cartSummary, additionalCost: 0 });
                      }
                    }}
                  />
                </div>
              </figure>
            </div>

            <form
              ref={chkFormRef}
              hidden={!isUserInfoConfirm}
              action="https://www.payfast.co.za/eng/process"
              name="form_47bac79857aa14a5fb18eff2991c43fb"
              method="POST"
              onSubmit={handleSubmit}
            >
              <input type="hidden" name="cmd" value="_paynow" />
              <input type="hidden" name="receiver" value="10961223" />
              <input
                type="hidden"
                name="item_name"
                value={`BZNTechnologies Sale - Order: ${orderNumber}`}
              />
              <input
                type="hidden"
                name="amount"
                value={cartSummary.itemsTotal + cartSummary.additionalCost}
              />
              <input
                type="hidden"
                name="item_description"
                value={cartState.items
                  .map((_v: any) => `${_v.count} ${_v.name}`)
                  .join(', ')}
              />
              <input
                type="hidden"
                name="return_url"
                value="https://bzntechnologies.com"
              />
              <input
                type="hidden"
                name="cancel_url"
                value="https://bzntechnologies.com"
              />
              <input
                type="hidden"
                name="notify_url"
                value={`${API_URL}/orders/confirm/${user?.token}`}
              />
              <button
                disabled={!cartState.items.length}
                type="submit"
                title="Pay Now with PayFast"
                value="Pay Now"
                className={`btn btn--large ${
                  isUserInfoConfirm ? 'btn--primary' : 'btn--warning'
                }`}
              >
                {!isUserInfoConfirm ? 'Confirm details' : 'Pay Now'}
              </button>
              <Link
                to={{
                  pathname: '/qoutation',
                  state: {
                    orderNumber,
                    ...cartSummary,
                  },
                }}
                className="btn btn--large"
              >
                Get Qoutation
              </Link>
            </form>
          </div>
        </div>
      </div>

      {/* END MAIN */}

      {/* DIALOG */}
      <Dialog
        open={false}
        onClose={() => setPaying(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Checkout Flow'}</DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button onClick={() => setPaidFor(false)} color="primary">
            Awesome!!!
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={paidFor}
        onClose={() => setPaidFor(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Hooray!!! your payment was successful'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please check your email for order confirmation, with details
            attached.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPaidFor(false)} color="primary">
            Awesome!!!
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={updateAddress}
        onClose={() => setUpdateAddress(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {user?.address
            ? 'Update address details'
            : 'Please confirm you address'}
        </DialogTitle>
        <DialogContent>
          <TextField
            name="phone"
            label="Phone Number"
            placeholder="0812345566"
            variant="outlined"
            defaultValue={user?.phone}
            onChange={(event: any) => {
              authDispatch({
                type: 'UPDATE_PROFILE',
                payload: { [event.target.name]: event.target.value },
              });
            }}
          />
          <TextField
            name="building"
            label="building"
            placeholder="building"
            variant="outlined"
            defaultValue={user?.address?.building}
            onChange={handleAddressFieldChange}
          />
          <TextField
            name="surburb"
            label="surburb"
            placeholder="surburb"
            variant="outlined"
            defaultValue={user?.address?.surburb}
            onChange={handleAddressFieldChange}
          />
          <TextField
            name="town"
            label="town"
            placeholder="town"
            variant="outlined"
            defaultValue={user?.address?.town}
            onChange={handleAddressFieldChange}
          />
          <TextField
            name="geolocation"
            label="geolocation"
            placeholder="geolocation"
            variant="outlined"
            defaultValue={user?.address?.geolocation}
            onChange={handleAddressFieldChange}
            onFocus={(event: any) => {
              //Request geolocation
              if (!navigator.geolocation) {
                window.alert('Geolocation is not supported on you browser');
              } else {
                navigator.geolocation.getCurrentPosition(success, error);
              }
            }}
          />
          <DialogContentText id="alert-dialog-description">
            Update your home and/or shipping address. <br />
            Tell us where should we send you newly bought items.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpdateAddress} color="primary">
            {user ? 'Confirm' : 'Update'} Address
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
}
function calcTotal(v: number) {
  return Math.round(v * Math.pow(10, 2)) / Math.pow(10, 2);
}
