import React, { FormEvent } from 'react';
import { Link, BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './main.scss';
import { CartProvider, AuthProvider, StockProvider } from './contexts';
import Register from './auth/Register';
import Login from './auth/Login';
import { TopNav } from './components/Nav';
import CheckOut from './pages/Checkout';
import ProductDetails from './pages/ProductDetails';
import { Index } from './pages/Index';
import OrderSlip from './pages/OrderSlip.js';
import { PrivateRoute } from './routes/PrivateRoute';
import Profile from './pages/Profile';

import { Terms, Privacy } from './pages/Legal';
import ProductList from './pages/ProductList';
import Results from './pages/Results';

import RefreshSync from './components/RefreshSync';
import Qoutation from './components/Qoutation';

function App() {
  return (
    <Router>
      <StockProvider>
        <AuthProvider>
          <CartProvider>
            <RefreshSync>
              <Switch>
                <Route exact path="/register" component={Register} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/" component={Index} />
                <Route exact path="/list/:department" component={ProductList} />
                <Route
                  exact
                  path="/product/:barcode"
                  component={ProductDetails}
                />
                <Route exact path="/results" component={Results} />
                <PrivateRoute exact path="/qoutation" component={Qoutation} />
                <Route exact path="/checkout" component={CheckOut} />
                <Route exact path="/checkout/orderslip" component={OrderSlip} />
                <Route exact path="/privacy" component={Privacy} />
                <Route exact path="/terms" component={Terms} />
                <PrivateRoute
                  exact
                  path="/purchase"
                  component={ConfirmPurchase}
                />
                <PrivateRoute exact path="/profile" component={Profile} />
                <Route path="*" component={NotFound} />
              </Switch>
            </RefreshSync>
          </CartProvider>
        </AuthProvider>
      </StockProvider>
    </Router>
  );
}

function ConfirmPurchase(props: any) {
  ///Handle purchase implementation on server
  function handleSubmit(ev: FormEvent) {
    ev.preventDefault();
  }
  return (
    <section className="page page--checkout">
      <TopNav {...props} />
      <div className="content content--col content--checkout">
        <div className="cart-wrapper">
          <h2>Complete purchase</h2>
          <p>
            It seems we are lost, <wbr /> let's get{' '}
            <strong>
              <Link to="/">back home.</Link>
            </strong>
          </p>
          <form onSubmit={handleSubmit}></form>
          <button className="btn btn--secondary">Get Qoutation</button>
          <button className="btn btn--secondary">Purchase</button>
          <button className="btn btn--secondary">Paypal</button>
        </div>
      </div>
    </section>
  );
}
export function NotFound(props: any) {
  return (
    <section className="page page--checkout">
      <TopNav {...props} />
      <div className="content content--col content--checkout">
        <div className="cart-wrapper">
          <h2>Whooops!</h2>
          <p>
            It seems we are lost, <wbr /> let's get{' '}
            <strong>
              <Link to="/">back home.</Link>
            </strong>
          </p>
        </div>
      </div>
    </section>
  );
}

export default App;
