import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="group group--large">
        <span className="logo"></span>
        <p>
          BZN Technologies (Pty) Ltd is a private company with a registration
          number 2014/026742/07, BZN Technologies (Pty) Ltd started its
          operations on 29 May 2013 before it was registered with Companies and
          Intellectual Property Commission on 07 February 2014.{" "}
        </p>
        {/* <div className="group group--strip">
          <span className="icon icon--med"></span>
          <span className="icon icon--med"></span>
          <span className="icon icon--med"></span>
        </div> */}
      </div>
      <div className="group">
        <h6>Pages</h6>
        <Link to="/">Home</Link>
        <Link to="/">About</Link>
        <Link to="/">Careers</Link>
      </div>
      <div className="group">
        <h6>Social</h6>
        <a target="_blank" href="https://www.facebook.com/BznTechnologies">
          Facebook
        </a>
        <a target="_blank" href="/">
          Twitter
        </a>
        <a target="_blank" href="/">
          Instagram
        </a>
      </div>
      <div className="group">
        <h6>Legal</h6>
        <Link to="/privacy">Privacy</Link>
        <Link to="/terms">Terms of use</Link>
      </div>
    </footer>
  );
}
