import React, { useEffect, useState } from 'react';
import {
  Text,
  Document,
  Font,
  Page,
  StyleSheet,
  Image,
  View,
  PDFDownloadLink,
} from '@react-pdf/renderer';
import { NotFound } from '../App';
import { PageWrap } from './Containers';
import LatoFont from '../fonts/Lato-Regular.ttf';
import { useLocation } from 'react-router-dom';
import { useAuthState } from '../contexts';

Font.register({
  family: 'Montserrat',
  src: 'http://fonts.gstatic.com/s/montserrat/v10/zhcz-_WihjSQC0oHJ9TCYC3USBnSvpkopQaUR-2r7iU.ttf',
});
console.log({ LatoFont });
const styles = StyleSheet.create({
  // Typography
  headText: {
    fontFamily: 'Montserrat',
    fontSize: 24,
  },
  subText: {
    fontFamily: 'Montserrat',
    fontSize: 10,
    opacity: 0.86,
  },
  label: {
    fontFamily: 'Montserrat',
    fontSize: 8,
    opacity: 0.7,
  },
  note: {
    opacity: 0.5,
    fontSize: 8,
  },

  // End Typography

  page: {
    padding: 40,
  },
  container: {
    flex: 1,
    flexDirection: 'row',
  },

  // Containers
  main: {
    fontFamily: 'Montserrat',
    marginTop: 100,
    fontSize: 14,
  },
  value: {
    display: 'flex',
    width: '50%',
  },
  wrapper: {
    width: '100%',
    height: '100%',
  },
  // End Containers

  // Start cart

  cart: {
    display: 'flex',
    flexDirection: 'column',
    border: '2px solid red',
  },
  cartItem: {
    display: 'flex',
    position: 'relative',
    backgroundColor: '#f1f1f1',
    padding: 8,
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: '#e6e7e7',
  },
  cartFooter: {
    display: 'flex',
    backgroundColor: '#f1f1f1',
    padding: 8,
  },
  // End cart

  // Positions
  right: {
    position: 'absolute',
    top: 8,
    right: 8,
    textAlign: 'left',
  },
  // End Position

  // Images, icons

  image: {
    objectFit: 'cover',
  },
  logo: {
    position: 'absolute',
    objectFit: 'cover',
    backgroundSize: 'cover',
    width: 100,
    top: 40,
    right: 40,
  },
  bg: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    opacity: 0.2,
  },

  // End Images, icons

  //Misc
  spacer: {
    marginTop: 40,
  },
  //End misc
  leftColumn: {
    flexDirection: 'column',
    width: 200,
    paddingTop: 30,
    paddingRight: 15,
    borderWidth: 4,
    borderColor: 'red',
    '@media max-width: 400': {
      width: '100%',
      paddingRight: 0,
    },
    '@media orientation: landscape': {
      width: 200,
    },
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: 40,
    marginTop: 25,
    paddingTop: 10,
    fontFamily: 'Montserrat',
    fontSize: 10,
    opacity: 0.76,
    '@media orientation: landscape': {
      marginTop: 10,
    },
  },
});

export const QoutationDocument = (props) => (
  <Document
    author="BZNTechnologies"
    keywords="awesome, Qoute, start wars"
    subject="Qoutation of "
    title="Qoute"
  >
    <Page size="A4" style={styles.page}>
      <Image
        style={styles.bg}
        src="https://images.pexels.com/photos/373076/pexels-photo-373076.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
      />
      {/* Header section */}
      <Image
        src="https://res.cloudinary.com/tumi/image/upload/v1590112488/image-removebg-preview.png"
        style={styles.logo}
      />
      <Text style={styles.headText}>Qoutation</Text>
      <Text style={styles.subtext}>{new Date().toLocaleDateString()}</Text>
      <View style={styles.spacer} />
      <Text style={styles.label}>Requested by</Text>
      <Text>{props.state.user.fullname}</Text>
      <Text>{props.state.user.email}</Text>

      {/* End Header section */}
      <View style={styles.main}>
        <View style={styles.cart}>
          <View
            style={{
              ...styles.cartItem,
              opacity: 0.7,
            }}
          >
            <Text style={styles.value}>Product</Text>
            <Text style={styles.right}>Price</Text>
          </View>
          {props.state.items.map((item) => (
            <View key={item.barcode} style={styles.cartItem}>
              <View style={styles.value}>
                <Text style={styles.label}>{item.barcode}</Text>
                <Text>{item.name}</Text>
              </View>
              <View style={styles.right}>
                <Text style={styles.label}>
                  {item.price} x {item.count}
                </Text>
                <Text>{item.subTotal}</Text>
              </View>
            </View>
          ))}
          {!!props.state.additionalCost && (
            <View style={styles.cartFooter}>
              <Text style={styles.label}>Delivery Fee</Text>
              <Text>{props.state.additionalCost}</Text>
            </View>
          )}
          <View style={styles.cartFooter}>
            <Text style={styles.label}>Total</Text>
            <Text>{props.state.additionalCost + props.state.itemsTotal}</Text>
          </View>
        </View>
      </View>
      {/* <Experience /> */}
      <Text style={styles.footer}>
        BZN Technologies (Pty) Ltd is a private company with a registration
        number 2014/026742/07, BZN Technologies (Pty) Ltd started its operations
        on 29 May 2013 before it was registered with Companies and Intellectual
        Property Commission on 07 February 2014.
      </Text>
    </Page>
  </Document>
);

export default function (props) {
  const { user } = useAuthState();
  const { state } = useLocation();

  const [ready, setReady] = useState(false);

  useEffect(() => {
    setReady(false);
    setReady(true);
    return () => setReady(false);
  });
  return (
    <PageWrap {...props}>
      {!user || (state.items && !state.items.length) ? (
        <NotFound />
      ) : (
        ready && (
          <div className="content">
            <h1>Qoutation</h1>
            <p>Please click the button below to download your qoute.</p>
            <PDFDownloadLink
              key="khgdyfgdsksksk"
              document={<QoutationDocument state={{ ...state, user }} />}
              className="btn"
              fileName="Qoutation.pdf"
              style={{ width: 'max-content' }}
            >
              Download Qoutation
            </PDFDownloadLink>
          </div>
        )
      )}
    </PageWrap>
  );
}

// ReactPDF.render(<Output />, `${__dirname}/output.pdf`);
