import React, { useState, useEffect } from 'react';
import { SideNav, TopNav } from '../components/Nav';
import Product from '../components/Product';
import Footer from '../components/Footer';
import { useFuzzyResult, useQuery } from './CustomHooks';
import flat from 'flat';
import { RangeSlider } from './ProductList';

export default function Results(props: any) {
  const [sorted, setSorted] = useState();
  const [sort, setSort] = useState('relevence');
  const [order, setOrder] = useState('');

  const { query } = useQuery();
  const [filteredProduct, _error, _mutate] = useFuzzyResult('');

  const [priceFiltered, setPriceFiltered] = useState<any[]>();

  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);

  const sortFn = (p: any, curr: any) => {
    const smallFirst = p[sort] < curr[sort] ? -1 : 1;
    const largeFirst = p[sort] > curr[sort] ? -1 : 1;
    return order.startsWith('ascend') ? smallFirst : largeFirst;
  };

  useEffect(() => {
    if (!filteredProduct) return;
    let _arr = [];
    const options = {
      safe: true,
    };
    _arr = filteredProduct.map((v: any) => flat(v, options)).sort(sortFn);
    setSorted(_arr);

    if (!_arr?.length) return;

    setMin(Math.min(_arr[0].price || 0, _arr[_arr.length - 1].price || 0));
    setMax(Math.max(_arr[0].price || 0, _arr[_arr.length - 1].price || 0));

    //
  }, [filteredProduct, sort, order]);

  useEffect(() => {
    if (!order) setOrder('descending');
    if (sort === 'relevance') {
      setSorted(undefined);
    }
  }, [sort]);

  return (
    <div className="root">
      <SideNav {...props} />
      <TopNav {...props} />
      <main className="main">
        <div className="content content--result">
          <h2>Search Results</h2>
          <p>
            showing results for <strong>{query}</strong>
          </p>
          <details className="controls-wrapper">
            <summary>
              Sort by{' '}
              <strong>{sort.includes('.') ? 'popularity' : sort}</strong>
            </summary>
            <div className="filter-group">
              <div className="filter">
                <div className="filter__option">
                  <input
                    type="radio"
                    name="filter"
                    checked={sort === 'relevence'}
                    id="relevance"
                    title="relevance"
                    onChange={() => setSort('relevence')}
                  />
                  <label htmlFor="relevance">relevance</label>
                </div>

                {[
                  { label: 'popularity', value: 'metrics.orders' },
                  { label: 'price' },
                  { label: 'name' },
                  { label: 'quantity' },
                ].map((v) => (
                  <div className="filter__option">
                    <input
                      type="radio"
                      name="filter"
                      onChange={() => {
                        setSort(v.value || v.label);
                      }}
                      checked={(v.value || v.label) === sort}
                      id={v.label}
                      title={v.value || v.label}
                    />
                    <label htmlFor={v.label}>{v.label}</label>
                  </div>
                ))}
              </div>
              <div className="filter">
                {['descending', 'ascending'].map((v) => (
                  <li className="filter__option">
                    <input
                      type="radio"
                      name="order"
                      checked={v === order}
                      onChange={() => setOrder(v)}
                      id={v}
                      title={v}
                      disabled={sort === 'relevance'}
                    />
                    <label htmlFor={v}>{v}</label>
                  </li>
                ))}
                <br />
                {sort === 'price' && filteredProduct?.length > 10 && (
                  <>
                    <RangeSlider
                      min={min}
                      max={max}
                      domain={[min, max]}
                      handleChange={([base, top]: number[]) => {
                        if (!(base || top)) return;
                        const _filtered = filteredProduct
                          ?.filter(
                            (v: any) => v.price >= base && v.price <= top
                          )
                          .sort(sortFn);
                        setPriceFiltered(_filtered);
                      }}
                    />
                    {priceFiltered && (
                      <button
                        onClick={() => setSorted(priceFiltered)}
                        className="btn"
                      >
                        Apply
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          </details>
          <div className="list list--grid">
            {(sorted || filteredProduct)?.map((p: any) => (
              <Product key={p.barcode} data={p} />
            ))}
          </div>
        </div>
        <Footer />
      </main>
    </div>
  );
}
