import React, { useState, useEffect, useRef } from 'react';
import { TopNav } from '../components/Nav';
import {
  useCartState,
  useCartDispatch,
  useAuthDispatch,
  useAuthState,
} from '../contexts';
import Axios from 'axios';
import { API_URL, fetcher, SecureHeader } from '../utils';
import { Link } from 'react-router-dom';
import {
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import {
  ShoppingBasketOutlined,
  ClearAllOutlined,
  Edit,
  BookmarkBorder,
  Remove,
} from '@material-ui/icons';
import Footer from '../components/Footer';
import useSWR from 'swr';
import { useForm } from 'react-hook-form';
import { StyledField } from '../components/Inputs';
import { persistCartProfile } from '../services/cart.service';

interface UserProfile {
  active: 0;
  picture: string;
  registered: Date;
  lastVisit: Date;
  visitCount: number;
  searchLog: any[];
  isAdmin: Boolean;
  electedBy?: string;
  _id: string;
  fullname: string;
  email: string;
  phone: string;
  passwordHash?: string;
  address?: {
    building: string;
    surburb: string;
    town: string;
    geolocation: string;
  };
  orderHistory: any[];
  incompleteCart: any[];
  __v: number;
}

const INIT_PROFILE = {
  active: 0,
  picture: '',
  registered: new Date(),
  lastVisit: new Date(),
  visitCount: 0,
  searchLog: [],
  isAdmin: false,
  electedBy: '',
  _id: '',
  fullname: '',
  email: '',
  phone: '',
  passwordHash: '',
  orderHistory: [],
  incompleteCart: [],
  __v: 0,
};

export default function Profile(props: any) {
  const [profile, setProfile] = useState(INIT_PROFILE);
  const cart = useCartState();
  const cartDispatch = useCartDispatch();
  const { register, handleSubmit, errors } = useForm();
  const [editMode, setEditMode] = useState(false);
  const profileFormRef = useRef<HTMLFormElement>(null);
  const authDispatch = useAuthDispatch();
  const authState = useAuthState();

  const { data, error, isValidating } = useSWR(
    `${API_URL}/auth/profile`,
    fetcher
  );

  const { data: stock } = useSWR(`${API_URL}/stock`, fetcher, {
    refreshInterval: 0,
  });

  useEffect(() => {
    //Fetch profile
    if (!data) return;
    console.log({ data });
    setProfile({ ...data, ...authState.user });
  }, [data, cart.wishlist]);

  const handleClearWishlist = () => {
    persistCartProfile([]);
    window.confirm('Confirm to empty your wishlist') &&
      cartDispatch({ type: 'CLEAR WISHLIST' });
  };

  const OrdersList = () => (
    <>
      {profile.orderHistory
        .slice()
        .reverse()
        .map((order: any) => {
          return (
            <figure className="order">
              <div>{order.orderNumber}</div>
              <div>{new Date(order.date).toLocaleDateString()}</div>
              <div>
                <div>
                  Order Total <strong>R{order.total}</strong>
                </div>
                <ul className="order-item">
                  {order.items.map((_item: any) => (
                    <li>
                      <Link
                        to={{
                          pathname: `/product/${_item?.barcode}`,
                          state: { ..._item, cart },
                        }}
                      >
                        {_item.name} * {_item.count}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </figure>
          );
        })}
    </>
  );

  const NoOrders = () => (
    <div className="banner banner--big">
      <h4>No previous orders</h4>
      <p>
        You still haven't bought anything from us yet. <br />
      </p>
      <Link className="btn" to="/">
        Start shopping
      </Link>
    </div>
  );

  const onSubmit = async (data: any) => {
    Axios.post(`${API_URL}/auth/profile`, data, { ...SecureHeader() })
      .then((res) => {
        authDispatch({ type: 'UPDATE_PROFILE', payload: data });
        setEditMode(false);
      })
      .catch((error) => {
        console.error(error?.response?.data);
      });
  };

  return (
    <div className="page">
      <Dialog
        open={editMode}
        onClose={() => setEditMode(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form ref={profileFormRef} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="alert-dialog-title">
            {'Update your profile!'}
          </DialogTitle>
          <DialogContent>
            <StyledField
              label="Fullname"
              name="fullname"
              placeholder="Fullname"
              type="text"
              required
              errors={errors}
              register={register}
              defaultValue={profile?.fullname}
            />
            <StyledField
              label="Phone"
              name="phone"
              placeholder="Phone"
              type="tel"
              required
              errors={errors}
              register={register}
              defaultValue={profile?.phone}
            />
            <StyledField
              label="Picture"
              name="picture"
              placeholder="Picture"
              type="url"
              required
              errors={errors}
              register={register}
              defaultValue={profile?.picture}
            />
          </DialogContent>
          <DialogActions>
            <Button color="primary" type="submit">
              Update Profile
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <TopNav {...props} />
      <div className="content content--profile">
        <div className="card card--profile">
          <IconButton onClick={() => setEditMode(true)} className="edit-btn">
            <Edit />
          </IconButton>
          <img
            className="img img--bg"
            src={profile?.picture}
            alt={profile.fullname}
          />
          <div className="label">
            <span>fullname</span>{' '}
            <label className="label__value">{profile?.fullname}</label>
          </div>
          <div className="label">
            <span>email</span>{' '}
            <label className="label__value">{profile?.email}</label>
          </div>
          <div className="label">
            <span>phone</span>{' '}
            <label className="label__value">
              {profile?.phone || 'NOT LINKED'}
            </label>
          </div>
          <div className="label">
            <span>registered</span>{' '}
            <label className="label__value">
              {new Date(profile?.registered).toDateString()}
            </label>
          </div>
        </div>
        <div>
          <h5>Your Order History</h5>
          <div className="list-wrap">
            <div className="list list--orders">
              {!profile.orderHistory.length ? <NoOrders /> : <OrdersList />}
            </div>
          </div>
        </div>

        <details
          id="wishlist"
          className="details details--profile"
          open={false}
        >
          <summary className="sect__head summary">
            <span className="section__title">
              <span>{cart.wishlist?.length}</span> Wishlist
            </span>
            <div style={{ marginLeft: 'auto' }}>
              <Button
                disabled={!profile?.incompleteCart.length}
                endIcon={<ClearAllOutlined />}
                onClick={handleClearWishlist}
              >
                Clear
              </Button>
            </div>
          </summary>
          {!cart.wishlist?.length ? (
            <div className="banner">
              <h4>Your wish is empty</h4>
              <p>
                Click{' '}
                <span>
                  <BookmarkBorder />
                </span>{' '}
                to add items to your wishlist, <br /> we'll notify you when we
                have new stock or on promo.
                <br />
                <Link className="btn" to="/">
                  Start shopping
                </Link>
              </p>
            </div>
          ) : (
            <div>
              <figure className="cart__item cart__item--head inline-table">
                <span>Product</span>
                <span className="right"></span>
              </figure>
              {cart.wishlist?.map((item: any) => {
                const itemFromStock = stock?.find(
                  (p: any) => p.barcode === item.barcode
                );
                return (
                  <figure
                    key={item.barcode}
                    className="cart__item inline-table flex"
                  >
                    <Link
                      to={{
                        pathname: `/product/${item.barcode}`,
                        state: { ...item },
                      }}
                      className="cart__item-label"
                    >
                      <span
                        className="img img--circle"
                        style={{
                          backgroundImage: `url(
                            ${'https://image.flaticon.com/icons/svg/2880/2880809.svg'}
                          )`,
                        }}
                      ></span>
                      <span>
                        <div className="cart__item-name">
                          {itemFromStock?.name || 'Unkown name'}
                        </div>
                        <div className="barcode">{item.barcode}</div>
                      </span>
                    </Link>
                    <div className="actions">
                      {false && (
                        <IconButton
                          onClick={() =>
                            cartDispatch({ type: 'ADD TO CART', payload: item })
                          }
                        >
                          <ShoppingBasketOutlined />
                        </IconButton>
                      )}
                      <IconButton
                        onClick={() =>
                          cartDispatch({
                            type: 'REMOVE FROM WISHLIST',
                            payload: item,
                          })
                        }
                      >
                        <Remove />
                      </IconButton>
                    </div>
                  </figure>
                );
              })}
            </div>
          )}
        </details>
      </div>
      <Footer />
    </div>
  );
}

function handleOnSubmit({ data, props }: any) {
  // dispatch({ type: "LOADING" });

  Axios.post(`${API_URL}/auth/login`, data)
    .then((res) => {
      console.log('Supppper', res);
    })
    .catch((error) => {
      console.log(error);
    });
}
