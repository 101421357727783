import Axios from 'axios';

export const API_URL =
  // process.env.NODE_ENV !== "production"
  //   ? "http://localhost:8080/api/v1"
  'https://bzntech-server.appspot.com/api/v1';
/**
 * API Request handler
 * @param url - api endpoint
 * @param method - http method
 * @param bodyParams - body parameters of request
 */
// import * as validator from "validator";
export const apiRequest = async (
  url: string,
  method: string,
  bodyParams: any
) => {
  const response = await fetch(url, {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: bodyParams ? JSON.stringify(bodyParams) : undefined,
  });
  return await response.json();
};

export function SecureHeader() {
  const { token } = JSON.parse(localStorage.getItem('session') || '');
  return {
    headers: {
      authorization: `Bearer ${token}`,
      'content-type': 'application/json',
    },
  };
}

export function getLocalCart(
  cartState = { items: [], wishlist: [], current: null }
) {
  const itemsStore = localStorage.getItem('bzn-cart');
  const wishlistStore = localStorage.getItem('bzn-wishlist');
  const items = itemsStore ? JSON.parse(itemsStore) : {};
  const wishlist = wishlistStore ? JSON.parse(wishlistStore) : {};
  const payload = { ...cartState, ...items, ...wishlist };

  console.log(payload);

  return payload;
}
export function getLocalSession() {
  const store = localStorage.getItem('session');
  const session = store ? JSON.parse(store) : null;
  return session;
}
export function addToCart(
  itemPicked: { barcode: any; count: any },
  state: { items: any[] }
) {
  /**
   * @returns new state
   */

  const itemAlreadyOnCart = state.items.find(
    (_item: { barcode: any }) => _item.barcode === itemPicked.barcode
  );
  let updatedCartItems;

  if (itemAlreadyOnCart) {
    const cartWithoutExistingItem = state.items.filter(
      (p: { barcode: any }) => p.barcode !== itemAlreadyOnCart.barcode
    );
    const newItem = {
      ...itemAlreadyOnCart,
      count: itemPicked.count || itemAlreadyOnCart.count + 1,
    };
    // updatedCartItems = [...cartWithoutExistingItem, { ...itemAlreadyOnCart, count: itemAlreadyOnCart.count + 1 }];
    cartWithoutExistingItem.unshift(newItem);
    updatedCartItems = [...cartWithoutExistingItem];
  } else {
    state.items.unshift({ ...itemPicked, count: itemPicked.count || 1 });
    updatedCartItems = state.items;
  }

  const updatedCart = { ...state, items: updatedCartItems };

  localStorage.setItem('bzn-cart', JSON.stringify(updatedCart));

  return updatedCart;
}
export const getToken = () => {
  return (
    (JSON.parse(localStorage.getItem('session') || '{}') || {}).token || ''
  );
};

// export function addToCart(itemPicked, state) {
//   /**
//    * @returns new state
//    */
//   const exists = !!state.items.find(
//     ({ barcode }) => barcode === itemPicked.barcode
//   );
//   if (exists) {
//     state.items = state.items.map((_item) => {
//       if (_item.barcode === itemPicked.barcode) {
//         return {
//           ..._item,
//           count: _item.count + (itemPicked.count || 1),
//         };
//       } else {
//         return _item;
//       }
//     });
//   } else {
//     state.items = [{ ...itemPicked, count: 1 }, ...state.items];
//   }
//   return state;
// }
/**Get All Stock from API
 *
 * @returns {Array} stock
 */
export async function getStock() {
  return (await (await Axios.get(`${API_URL}/stock`)).data) || [];
}
/**
 * Direct edit Cart Item
 *
 * @summary Will take item to edit then return UPDATED STATE
 * @param {object} item, actual item to edit
 * @param {object} state, actual item to edit
 * @returns state - updated state
 */

export function validatePayload(value: ValidationPayload) {
  if (typeof value.payload !== value.expctedPayload)
    throw new Error(
      `Reecieved payload of type ${typeof value.payload} instead of ${
        value.expctedPayload
      }!`
    );
  return;
}

interface ValidationPayload {
  expctedPayload: any;
  payload: any;
}

export function hasEmptyFields(
  obj: { [s: string]: unknown } | ArrayLike<unknown>
) {
  return Object.values(obj).some((v) => v === '' || v === undefined);
}

/**
 * Recursively flattens a JSON object using dot notation.
 *
 * NOTE: input must be an object as described by JSON spec. Arbitrary
 * JS objects (e.g. {a: () => 42}) may result in unexpected output.
 * MOREOVER, it removes keys with empty objects/arrays as value (see
 * examples bellow).
 *
 * @example
 * // returns {a:1, 'b.0.c': 2, 'b.0.d.e': 3, 'b.1': 4}
 * flatten({a: 1, b: [{c: 2, d: {e: 3}}, 4]})
 * // returns {a:1, 'b.0.c': 2, 'b.0.d.e.0': true, 'b.0.d.e.1': false, 'b.0.d.e.2.f': 1}
 * flatten({a: 1, b: [{c: 2, d: {e: [true, false, {f: 1}]}}]})
 * // return {a: 1}
 * flatten({a: 1, b: [], c: {}})
 *
 * @param obj item to be flattened
 * @param {Array.string} [prefix=[]] chain of prefix joined with a dot and prepended to key
 * @param {Object} [current={}] result of flatten during the recursion
 *
 * @see https://docs.mongodb.com/manual/core/document/#dot-notation
 */
export function flatten(
  obj: { [x: string]: any } | null,
  prefix: any[],
  current: { [x: string]: any }
) {
  prefix = prefix || [];
  current = current || {};

  // Remember kids, null is also an object!
  if (typeof obj === 'object' && obj !== null) {
    Object.keys(obj).forEach((key) => {
      flatten(obj[key], prefix.concat(key), current);
    });
  } else {
    current[prefix.join('.')] = obj;
  }

  return current || {};
}

export const fetcher = (url: string) =>
  Axios.get(url, SecureHeader()).then((res: any) => res.data);
