import React, { useEffect, useState } from 'react';
import { useFilteredProducts } from './CustomHooks';
import { SideNav, TopNav } from '../components/Nav';
import Product from '../components/Product';
import Footer from '../components/Footer';
import flat from 'flat';
import { Slider, Typography } from '@material-ui/core';
import { StockItem } from '../models/stock';

export default function ProductList(props: any) {
  const {
    match: { params },
  } = props;
  const [filteredProduct] = useFilteredProducts(params.department);

  const [sorted, setSorted] = useState<StockItem[]>();
  const [sort, setSort] = useState('metrics.orders');
  const [order, setOrder] = useState('descending');

  const [priceFiltered, setPriceFiltered] = useState<StockItem[]>();

  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [domain, setDomain] = React.useState<number[]>([0, 0]);

  const sortFn = (p: any, curr: any) => {
    const smallFirst = p[sort] < curr[sort] ? -1 : 1;
    const largeFirst = p[sort] > curr[sort] ? -1 : 1;
    return order.startsWith('ascend') ? smallFirst : largeFirst;
  };
  useEffect(() => {
    if (!filteredProduct) return;

    let _arr = [];
    const options = {
      safe: true,
    };
    _arr = filteredProduct.map((v: any) => flat(v, options)).sort(sortFn);
    setSorted(_arr);

    if (!_arr?.length) return;

    setMin(Math.min(_arr[0].price || 0, _arr[_arr.length - 1].price || 0));
    setMax(Math.max(_arr[0].price || 0, _arr[_arr.length - 1].price || 0));

    //
  }, [filteredProduct, sort, order]);

  const deptString =
    params.department.substring(0, 1).toUpperCase() +
    params.department.substring(1, params.department.length);
  return (
    <div className="root">
      <SideNav {...props} />
      <TopNav {...props} />
      <main className="main">
        <div className="content content--result">
          <div>
            <span className="barcode">showing</span> <br />{' '}
            <h2>{deptString}</h2>
          </div>
          <details className="controls-wrapper">
            <summary>
              Sort by{' '}
              <strong>{sort.includes('.') ? 'popularity' : sort}</strong>
            </summary>
            <div className="filter-group">
              <div className="filter">
                {[
                  { label: 'popularity', value: 'metrics.orders' },
                  { label: 'price' },
                  { label: 'name' },
                  { label: 'quantity' },
                ].map((v) => (
                  <div className="filter__option">
                    <input
                      type="radio"
                      name="filter"
                      onChange={(ev: any) => {
                        setSort(v.value || v.label);
                      }}
                      checked={(v.value || v.label) === sort}
                      id={v.label}
                      title={v.value || v.label}
                    />
                    <label htmlFor={v.label}>{v.label}</label>
                  </div>
                ))}
              </div>
              <div className="filter">
                {['descending', 'ascending'].map((v) => (
                  <li className="filter__option">
                    <input
                      type="radio"
                      name="order"
                      checked={v === order}
                      onChange={() => setOrder(v)}
                      id={v}
                      title={v}
                    />
                    <label htmlFor={v}>{v}</label>
                  </li>
                ))}
              </div>
            </div>
            <div className="wide">
              {sort === 'price' && filteredProduct?.length > 10 && (
                <>
                  <RangeSlider
                    min={min}
                    max={max}
                    domain={[min, max]}
                    handleChange={([base, top]: number[]) => {
                      if (!(base || top)) return;
                      const _filtered = filteredProduct
                        ?.filter(
                          (v: any) =>
                            v.price >= Math.min(base, top) &&
                            v.price <= Math.max(base, top)
                        )
                        .sort(sortFn);
                      setPriceFiltered(_filtered);
                    }}
                  />
                  {priceFiltered && (
                    <button
                      onClick={() => setSorted(priceFiltered)}
                      className="btn"
                    >
                      Apply
                    </button>
                  )}
                </>
              )}
            </div>
          </details>
          <div className="list list--grid">
            {(sorted || filteredProduct)?.map((p: any) => (
              <Product key={p.barcode} data={p} />
            ))}
          </div>
        </div>
        <Footer />
      </main>
    </div>
  );
}

function valuetext(value: number) {
  return `R${value}`;
}

export function RangeSlider(props: any) {
  console.log({ ...props });
  const [value, setValue] = React.useState<number[]>([0, 0]);
  const [defaultValue, setDefaultValue] = React.useState(props.domain);

  const handleChange = (event: any, newValue: number | number[]) => {
    setValue(newValue as number[]);
    props.handleChange(newValue as number[]);
  };

  useEffect(() => {
    setDefaultValue(props.domain);
  }, [props.domain]);
  return (
    <div>
      <Typography id="range-slider" className="barcode">
        Price range <strong>R{Math.min(value[0], value[1])}</strong> -{' '}
        <strong>R{Math.max(value[0], value[1])}</strong>
      </Typography>
      <Slider
        value={value || defaultValue}
        defaultValue={defaultValue}
        onChange={handleChange}
        min={props.min}
        max={props.max}
        step={1000}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        getAriaValueText={valuetext}
      />
    </div>
  );
}
