import { API_URL, getStock } from "../../utils";

//actions
export const INITIAL = "INITIAL";
export const FETCH_STOCK = "FETCH_STOCK";
export const ADD_STOCK = "ADD_STOCK";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_DEPARTMENT = "SET_DEPARTMENT";
export const SEARCH_STOCK = "SEARCH_STOCK";
export const ERROR = "ERROR";

//action creators
export const fetchStock = (data) => ({ type: FETCH_STOCK, payload: data });
export const fetchInitial = (data) => ({ type: INITIAL, payload: data });
export const addStock = (data) => ({ type: ADD_STOCK, payload: data });

//reducer1
export function stockReducer(state, action) {
  switch (action.type) {
    case INITIAL: {
      return { items: [] };
    }
    case SET_PRODUCTS: {
      return { items: action.playload };
    }
    case SET_DEPARTMENT: {
      return {
        ...state,
        departments: { [action.payload.name]: action.payload.items },
      };
    }
    case ADD_STOCK: {
      const stock = [...state.stock, action.payload];
      return { stock };
    }
    case FETCH_STOCK: {
      return { stock: action.payload };
    }
    case SEARCH_STOCK: {
      return { state, results: state.stock.filter };
    }
    case ERROR: {
      return { error: action.payload };
    }

    //!!FIXME: Some wonky shit about to go down from here
    //Find the recommended way to do this.
    case "DIRECT_REFFRESH_STOCK": {
      const stock = getStock();
      return { ...state, stock };
    }

    //Pew
    default: {
      throw new Error(`Unhandle action type: ${action.type}`);
    }
  }
}

// const stockClient = new StockClient();

// export async function fetchStockBind(dispatch) {
//   const data = await stockClient.getStock();
//   dispatch(fetchStock(data));
// }
// export async function addStockBind(dispatch) {
//   const data = await stockClient.getStock();
//   dispatch(fetchStock(data));
// }
