import React, { createContext } from "react";

import { stockReducer } from "./reducers";

const StockStateContext = createContext();
const StockDispatchContext = createContext();

function StockProvider({ children }) {
  const [state, dispatch] = React.useReducer(stockReducer, { stock: [] });
  return (
    <StockStateContext.Provider value={state}>
      <StockDispatchContext.Provider value={dispatch}>{children}</StockDispatchContext.Provider>
    </StockStateContext.Provider>
  );
}

function useStockState() {
  const context = React.useContext(StockStateContext);
  if (context === undefined) {
    throw new Error("useStockState must be within StockProvider");
  }
  return context;
}
function useStockDispatch() {
  const context = React.useContext(StockDispatchContext);
  if (context === undefined) {
    throw new Error("useStockDispatch must be within a StockProvider");
  }
  return context;
}

export { StockProvider, useStockState, useStockDispatch };
