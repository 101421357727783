import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import { BookmarkBorder } from '@material-ui/icons';

import { useCartState, useCartDispatch } from '../contexts';

import { useFilteredProducts } from './CustomHooks';
import { API_URL } from '../utils';
import { Page } from '../components/Containers';
import useSWR from 'swr';

export default function ProductDetails(props: any) {
  const fetcher = (url: string) => fetch(url).then((r: any) => r.json());
  const { data: stock } = useSWR(`${API_URL}/stock`, fetcher);

  const loadingIcon =
    'https://res.cloudinary.com/swssr/image/upload/v1583468176/bzntech/cqrlk59fxsqttgiwivxl.gif';
  const GENERIC_PRODUCT =
    'https://image.flaticon.com/icons/svg/2880/2880809.svg';
  const INIT = {
    name: '',
    barcode: '',
    price: 0,
    prevPrice: null,
    description: '',
    featured: false,
    images: [loadingIcon],
    department: ['any'],
    quantity: 1,
  };

  const [item, set] = useState(INIT);
  const { barcode: _barcode } = useParams();

  const [similarProducts] = useFilteredProducts(item?.department[0]);
  const [count, setCount] = useState(1);
  const [currentPreview, setCurrentPreview] = useState(GENERIC_PRODUCT);

  const cartState = useCartState();
  const cartDispatch = useCartDispatch();

  const countInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!stock) return;
    const current = stock.find((item: any) => item.barcode === _barcode);
    current && set(current);

    //Set up cart counter
    // setCount(0);
    const itemOnCartCount =
      cartState.items.find((item: any) => item.barcode === _barcode)?.count ||
      1;
    setCount(itemOnCartCount);
    setCurrentPreview(item?.images[0] || GENERIC_PRODUCT);
    console.log({ current });
  }, [_barcode, stock, cartState, count]);

  useEffect(() => {
    if (!targetRef.current) return;

    console.log('Should scroll to top');
    targetRef.current.scrollIntoView();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [item, _barcode]);
  // const item = props.location.state;
  // // const item = useStockState().stock[0];

  function handleCountChange(increment: number, direct?: boolean) {
    if (count >= 0 && !direct) {
      setCount(count + increment);
    } else {
      setCount(Math.abs(increment));
    }
  }

  function handleCartAdd(product: any) {
    const itemOnCart = cartState.items.find(
      (_p: any) => _p.barcode === _barcode
    );
    if (product.quantity - 1 < itemOnCart?.count) return;
    cartDispatch({ type: 'ADD TO CART', payload: item });
  }

  function handleWishlistAdd(product: any) {
    const itemOnCart = cartState.items.find(
      (_p: any) => _p.barcode === product.barcode
    );
    if (product.quantity - 1 < itemOnCart?.count) return;
    cartDispatch({ type: 'ADD TO WISHLIST', payload: product });
  }

  function canAddToCart(barcode: string, cart = cartState) {
    const alreadyExists = new Set(cart.items.map((p: any) => p.barcode)).has(
      barcode
    );

    //FIXME: Count for some reason wount sync up with cart item state count.
    //It's always a step behind.
    //This kinda feel slow
    const currentItem = cartState.items.find((p: any) => p.barcode === barcode);
    return alreadyExists && item?.quantity <= (currentItem?.count || 0);
  }

  function handleImageClick(event: any) {
    const { src } = event.currentTarget;
    src && setCurrentPreview(src);
  }
  const suggestedListStyle = { '--num-suggested': 6 } as React.CSSProperties;

  const targetRef = useRef<HTMLDivElement>(null);

  return (
    <Page {...props}>
      <div className="target" ref={targetRef}></div>
      <div className="details-wrap">
        <div className="details-grid">
          <div className="product-image-wrap">
            <div className="img-wrap">
              <img
                data-preview-curr
                src={currentPreview}
                alt={item?.description}
                className="img"
              />
            </div>
            <div className="img-wrap">
              <img
                data-preview-curr
                src={item?.images[0] || GENERIC_PRODUCT}
                alt={item?.description}
                className="img"
                onClick={handleImageClick}
              />
            </div>
            <div className="img-wrap">
              {item?.images[1] && (
                <img
                  data-preview-next
                  src={item?.images[1]}
                  alt="preview 2"
                  className="img img--small"
                  onClick={handleImageClick}
                />
              )}
            </div>
            <div className="img-wrap">
              {item?.images[2] && (
                <img
                  data-preview-next
                  src={item?.images[2]}
                  alt="preview 3"
                  className="img img--small"
                  onClick={handleImageClick}
                />
              )}
            </div>
          </div>
          <div className="details">
            <IconButton
              onClick={() => handleWishlistAdd(item)}
              className="fab fab--tr"
            >
              <BookmarkBorder />
            </IconButton>
            <h1 className="details__name">{item?.name}</h1>
            <div className="barcode">{item?.barcode}</div>
            <p>
              {item?.description}
              <br />
            </p>
            {item?.quantity > 0 && <span>{item?.quantity} on stock</span>}
            <div className="flex flex--between flex--down">
              {item?.quantity > 0 ? (
                <div className="counter">
                  {props.label && <label htmlFor="">{props.label}</label>}
                  {!!cartState.items.find(
                    (_p: any) => _p.barcode === _barcode
                  ) && (
                    <div className="inputs">
                      <span
                        onClick={(event: any) => {
                          cartDispatch({
                            type: 'EDIT CART ITEM',
                            payload: {
                              ...item,
                              count: count - 1,
                            },
                          });
                        }}
                      >
                        -
                      </span>
                      <input
                        id={'counter'}
                        type="number"
                        value={count}
                        ref={countInputRef}
                        min={1}
                        max={item?.quantity}
                        step={1}
                        onMouseOver={(event: any) => event.target.select()}
                        onFocus={(event: any) => event.target.select()}
                        onChange={(event: any) => {
                          const { value } = event.target;
                          if (value > item?.quantity || !value || value < 1)
                            return;
                          setCount(value);
                          cartDispatch({
                            type: 'ADD TO CART',
                            payload: {
                              ...item,
                              count: Math.abs(+value),
                            },
                          });
                        }}
                      />
                      <span
                        onClick={(event: any) => {
                          if (count >= item?.quantity) return;
                          setCount(count + 1);
                          cartDispatch({
                            type: 'EDIT CART ITEM',
                            payload: {
                              ...item,
                              count: count + 1,
                            },
                          });
                        }}
                      >
                        +
                      </span>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <h3>Out of stock</h3>
                </div>
              )}
              <div className="prices">
                <span className="product__price">R{item?.price}</span>
                {item.featured && (
                  <span className="product__price product__price--markdown">
                    R{item?.prevPrice}
                  </span>
                )}
              </div>
            </div>

            <div className="actions">
              <button
                onClick={() => props.history.push('/')}
                className="btn btn--large"
              >
                Continue Shopping
              </button>
              {item.quantity > 0 ? (
                <button
                  title={
                    canAddToCart(_barcode || '')
                      ? "Sorry, we're short on stock!"
                      : ''
                  }
                  disabled={canAddToCart(_barcode || '')}
                  onClick={() => handleCartAdd(item)}
                  className="btn btn--secondary btn--large"
                >
                  Add to Cart
                </button>
              ) : (
                <button
                  onClick={() => handleWishlistAdd(item)}
                  className="btn btn--secondary btn--large"
                >
                  Add to wishlist
                </button>
              )}
              <button
                onClick={() => props.history.push('/checkout')}
                className="btn btn--primary btn--large"
              >
                Proceed to Checkout
              </button>
            </div>
          </div>
        </div>
        <section className="suggestions">
          <div className="section__head">
            <h3 className="section__title">Similar products</h3>
            <span>{similarProducts?.length || 0}</span>
          </div>
          <div>
            <ul style={suggestedListStyle} className="suggestions-list">
              {similarProducts
                ?.slice(0, 5)
                .reverse()
                .map((p: any) => (
                  <>
                    <Link
                      key={p.barcode}
                      to={{
                        pathname: `/product/${p.barcode}`,
                        state: p,
                      }}
                      className="card"
                      style={{
                        backgroundImage:
                          'url(' + p.images[0] || loadingIcon + ')',
                      }}
                    ></Link>
                  </>
                ))}
            </ul>
          </div>
        </section>
      </div>
    </Page>
  );
}
