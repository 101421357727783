import React, { useEffect } from 'react';
import { useAuthDispatch, useAuthState } from '../contexts';
import Axios from 'axios';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { API_URL } from '../utils';
import Footer from '../components/Footer';
import { TopNav } from '../components/Nav';
import { StyledForm, Row, StyledField } from '../components/Inputs';
import { StyledChip } from '../components/Chips';

export default function Login(props: any) {
  const dispatch = useAuthDispatch();
  const state = useAuthState();
  useEffect(() => {
    console.log(API_URL);
    return dispatch({ type: 'RESET' });
  }, [dispatch]);

  const { register, handleSubmit, errors } = useForm();
  const onSubmit = async (data: any) =>
    handleOnSubmit({ data, dispatch, props });

  const title = state.new ? 'Hoooray! Welcome.' : 'Welcome back!';

  function handleFocus() {
    !state.loading && dispatch({ type: 'RESET' });
  }
  return (
    <div className="page page--auth">
      <TopNav {...props} />
      <div className="content content--auth">
        <StyledForm
          onSubmit={handleSubmit(onSubmit)}
          onFocus={() => dispatch({ type: 'RESET' })}
        >
          <h1 className="title">{title}</h1>
          <p className="subtitle">Good to see you again.</p>
          {state.loading && (
            <StyledChip type="base">Loading, Waiting for server....</StyledChip>
          )}
          {state.success && (
            <StyledChip type="success">Any moment now... Awesome!</StyledChip>
          )}
          {state.error && <StyledChip type="error">{state.error}</StyledChip>}
          <StyledField
            onFocus={handleFocus}
            label="Email"
            name="email"
            placeholder="human@store.com"
            type="email"
            required
            errors={errors}
            register={register}
          />
          <StyledField
            onFocus={handleFocus}
            label="Password"
            name="password"
            placeholder="password"
            type="password"
            required
            errors={errors}
            register={register}
          />
          <Row>
            <button className="btn btn--primary" type="submit">
              Login
            </button>
            <Link className="btn" to="/register">
              Register
            </Link>
          </Row>
        </StyledForm>
      </div>
      <Footer />
    </div>
  );
}

function handleOnSubmit({ data, dispatch, props }: any) {
  dispatch({ type: 'LOADING' });

  Axios.post(`${API_URL}/auth/login`, data)
    .then((res) => {
      const session = res.data;
      localStorage.setItem('session', JSON.stringify(session));

      dispatch({ type: 'SUCCESS' });
      dispatch({ type: 'LOGIN', payload: session });

      setTimeout(() => {
        props.history.go(-1);
      }, 1000);
    })
    .catch((error) => {
      if (error.response && typeof error.response.data === 'string') {
        return dispatch({ type: 'ERROR', payload: error.response.data });
      }
      if (error.response) {
        const { error: customServerError } = error.response.data;
        return dispatch({ type: 'ERROR', payload: customServerError });
      }
      dispatch({ type: 'ERROR', payload: error.message });
      // console.log(error);
    });
}
