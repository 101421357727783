import React from "react";
import Styled from "styled-components";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { Link } from "react-router-dom";

const mockOrder = {
  customerDetails: {
    fullname: "Simo Ngquseka",
    email: "simo@swssr.com",
    phone: "0796441784",
  },
  shippingDetails: {
    building: "Home bro",
    surburb: "Nikhwe",
    town: "Bizana",
    geolocation: "-33.9258 18.4232",
  },
  isPaid: true,
  items: [
    {
      barcode: "PAPER-TYPEK-CODE",
      name: "Typek paper",
      count: 100,
      price: 600,
      date: "22-12-2019",
    },
    {
      barcode: "MAC-PRO-16-INCH",
      name: "Macbook Pro 15inch 2019",
      price: 34000,
      count: 100,
    },
  ],
};
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",

    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

// Create Document Component
export default () => (
  <div className="page">
    <PDFDownloadLink
      document={
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.section}>
              <Text>Section #1</Text>
            </View>
            <View style={styles.section}>
              <Text>Section #2</Text>
            </View>
          </Page>
        </Document>
      }
    >
      Download pdf
    </PDFDownloadLink>
  </div>
);

function getDeliveryStatus(order) {
  //0 is pending checkout, 1 is pending delivery and 2 is delivered.
  switch (order.deliverStatus) {
    case 1:
      return "en route";
    case 2:
      return "delivered";
    default:
      return "pending checkout";
  }
}
export function OrderSlip(props) {
  const order = mockOrder;
  if (!order) {
    return (
      <div className="container">
        <h3>No order selected</h3>
        <p>
          Please go back and select an order by clicking{" "}
          <strong>
            <Link to="/reports">here</Link>
          </strong>
        </p>
      </div>
    );
  }

  return (
    <div className="container--order-details">
      <Group gap="8px">
        <h3>Order details</h3>
        <h2>{order.orderNumber}</h2>
        {/* !!FIXME: USE INTL DATA */}
        <div>{new Date(order.date).toLocaleDateString()}</div>

        <PillList>
          {order.isPaid && <span className="pill">is-paid</span>}
          {order.isCheckedOut && <span className="pill">is-seen</span>}
          {order.isPaid && <span className="pill">is-paid</span>}
          {order.isPaid && <span className="pill">is-paid</span>}
        </PillList>
      </Group>
      <Group>
        <h5>Personal</h5>
        <ValueLabel>
          <label>Fullname</label>
          <strong>{order.customerDetails.fullname}</strong>
        </ValueLabel>
        <ValueLabel>
          <label>Email</label>
          <strong>{order.customerDetails.email}</strong>
        </ValueLabel>
        <ValueLabel>
          <label>Phone</label>
          <strong>{order.customerDetails.phone}</strong>
        </ValueLabel>
      </Group>
      <Group>
        <h5>Shipping</h5>
        <ValueLabel>
          <label>Building</label>
          <strong>{order.shippingDetails.building}</strong>
        </ValueLabel>
        <ValueLabel>
          <label>Surburb</label>
          <strong>{order.shippingDetails.surburb}</strong>
        </ValueLabel>
        <ValueLabel>
          <label>Town</label>
          <strong>{order.shippingDetails.town}</strong>
        </ValueLabel>
        <ValueLabel>
          <label>Geolocation</label>
          <strong title="open on google maps">
            <Link
              to={`https://maps.google.com/geo::${order.shippingDetails.geolocation}`}
              target="_blank"
            >
              {order.shippingDetails.geolocation}
            </Link>
          </strong>
        </ValueLabel>
      </Group>
      <Group>
        <details>
          <summary>Total items {order.items.length}</summary>
          <List>
            {order.items.map((item) => (
              <ListItem key={item.barcode}>
                <div className="lbpair">
                  <span>barcode</span>
                  <strong>{item.barcode}</strong>
                </div>
                <div className="lbpair">
                  <span>Count</span>
                  <strong>{item.count}</strong>
                </div>
                <div className="lbpair">
                  <span>Price</span>
                  <strong>R{item.soldFor}</strong>
                </div>
                <div className="lbpair">
                  <span>Tax</span>
                  <strong>R{item.tax}</strong>
                </div>
                <div className="lbpair">
                  <span>Subtotal</span>
                  <strong>R{item.subTotal}</strong>
                </div>
              </ListItem>
            ))}
          </List>
        </details>
      </Group>
      <GroupPrimary flex>
        <ValueLabel>
          <label>Total</label>
          <h4>R{order.total}</h4>
        </ValueLabel>
        <ValueLabel>
          <label>Status</label>
          <h4>{getDeliveryStatus(order)}</h4>
        </ValueLabel>
      </GroupPrimary>
    </div>
  );
}
export const ValueLabel = Styled.div`
    display: grid;
    gap: .5rem;
    border-bottom: 2px solid rgba(#000, .4)
  `;
export const List = Styled.ul`
  `;

export const Group = Styled.div`
    display: ${({ flex }) => (flex ? "flex" : "grid")};
    gap: ${({ gap }) => gap || "1rem"};
    padding: 1rem;
    margin-bottom: .5rem;
  `;
export const GroupPrimary = Styled(Group)`
    background: #fff
  
  `;
export const PillList = Styled.ul`
    display: flex;
    gap: 0.5rem;
  `;

export const ListItem = Styled.li`
  width: 100%;
  display: grid;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: flex-start;
  text-decoration: none;
  `;
